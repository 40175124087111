<template>
  <div class="h-100">
    <div class="">
      <div class="d-flex flex-column flex-md-row justify-content-between align-items-center mt-3 mb-md-3 mt-md-0">
        <h3 class="mb-3 m-md-0">Recherche avancée</h3>
        <div class="d-flex flex-column flex-md-row justify-content-end align-items-center">
          <div @click="getAffaires" class="mr-md-3 mb-3 mb-md-0 text-primary cursor-pointer">
            Rafraichir les données
          </div>
          <div @click="initFilters" class="mr-md-3 mb-3 mb-md-0 text-primary cursor-pointer">Effacer les filtres</div>
          <!-- RÉSULTATS INDICATEURS -->
          <div class="d-flex ml-md-3 mt-3 mt-md-0" v-if="!loading">
            <div class="btn-group ml-auto" role="group">
              <download-excel :data="affairesPourExport()" class="btn btn-primary cursor-pointer" name="affaires.xls">
                Exporter (.xls)
                <span class="badge badge-warning">{{ affairesFiltered.length }}</span>
              </download-excel>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class>
          <div class="d-flex flex-column flex-md-row mb-3">
            <filtre-select
              class="mr-md-3 mt-3 mt-md-0"
              inputText="Société"
              v-model.number="filtreSociete"
              :options="data.societes"
              :disabled="false"
              field="societe_nom_reduit"
            ></filtre-select>
            <filtre-select
              class="mr-md-3 mt-3 mt-md-0"
              inputText="Pilote"
              v-model.number="filtrePilote"
              :options="users"
              :disabled="false"
              field="collaborateur_nom"
            ></filtre-select>
            <filtre-select
              class="mr-md-3 mt-3 mt-md-0"
              inputText="Etat"
              v-model.number="filtreEtat"
              :options="data.etats"
              :disabled="false"
              field="description"
            ></filtre-select>
            <filtre-select
              class="mt-3 mt-md-0"
              inputText="Année"
              v-model.number="filtreAnnee"
              :options="annees"
              :disabled="false"
              field="description"
            ></filtre-select>
          </div>
          <div class="d-flex flex-column flex-md-row">
            <filtre-select
              class="mr-md-3 mt-3 mt-md-0"
              inputText="ADR"
              v-model.number="filtreAdr"
              :options="options"
              :disabled="false"
              field="description"
            ></filtre-select>
            <filtre-select
              class="mr-md-3 mt-3 mt-md-0"
              inputText="PDP"
              v-model.number="filtrePdp"
              :options="options"
              :disabled="false"
              field="description"
            ></filtre-select>
            <filtre-select
              class="mr-md-3 mt-3 mt-md-0"
              inputText="EDP"
              v-model.number="filtreEdp"
              :options="options"
              :disabled="false"
              field="description"
            ></filtre-select>
            <filtre-select
              class="mt-3 mt-md-0"
              inputText="RTR"
              v-model.number="filtreRtr"
              :options="options"
              :disabled="false"
              field="description"
            ></filtre-select>
          </div>
        </div>
      </div>
    </div>
    <!-- RESULTATS -->
    <div v-if="!loading" class="d-flex flex-column flex-md-row flex-md-wrap mt-2 mb-4">
      <div v-for="affaire in affairesFiltered" :key="affaire.id" class="width-item">
        <item :data="affaire" @show="openAffaire" />
      </div>
    </div>
    <!-- LOADER -->
    <div class="d-flex align-items-center" v-if="loading">
      <loader class="m-5"></loader>
      <h2 class="m-0">Chargement...</h2>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import FiltreSelect from "@/components/collaborateurs/recherche/FiltreSelect.vue";
import Loader from "@/components/bases/Loader.vue";
import item from "@/components/affaires/recherche/item.vue";
// import { mapActions } from "vuex";

export default {
  components: {
    Loader,
    FiltreSelect,
    item,
  },
  data() {
    return {
      affaires: [],
      loading: false,
      showFilters: false,
      filtreSociete: null,
      filtrePilote: null,
      filtreEtat: null,
      filtreAnnee: null,
      filtreAdr: null,
      filtrePdp: null,
      filtreEdp: null,
      filtreRtr: null,
      annees: [
        { id: this.$moment().format("YYYY"), description: this.$moment().format("YYYY") },
        { id: this.$moment().format("YYYY") - 1, description: this.$moment().format("YYYY") - 1 },
        { id: this.$moment().format("YYYY") - 2, description: this.$moment().format("YYYY") - 2 },
        { id: this.$moment().format("YYYY") - 3, description: this.$moment().format("YYYY") - 3 },
        { id: this.$moment().format("YYYY") - 4, description: this.$moment().format("YYYY") - 4 },
        { id: this.$moment().format("YYYY") - 5, description: this.$moment().format("YYYY") - 5 },
      ],
      options: [
        { id: 1, description: "Avec" },
        { id: 0, description: "Sans" },
        { id: 2, description: "NA" },
      ],
    };
  },
  methods: {
    initFilters() {
      this.filtreSociete = null;
      this.filtrePilote = null;
      this.filtreEtat = null;
      this.filtreAnnee = null;
      this.filtreAdr = null;
      this.filtrePdp = null;
      this.filtreEdp = null;
      this.filtreRtr = null;
    },
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
    getAffaires() {
      this.loading = true;
      this.$http
        .get("affaires-recherche")
        .then((response) => {
          this.affaires = response.data;
        })
        .finally(() => (this.loading = false));
    },
    openAffaire(id) {
      let routeData = this.$router.resolve({
        name: "crm_affaire",
        params: { id: id },
      });
      window.open(routeData.href, "_blank");
    },

    getValuebyId(data, field, id) {
      if (data) {
        let item = data.filter((item) => item.id === id)[0];
        if (item) return item[field];
      }
      return "";
    },

    affairesPourExport() {
      let data = [];

      this.affairesFiltered.forEach((affaire) => {
        if (this.data && this.data.etats && this.data.societes)
          data.push({
            ["identifiant"]: affaire.affaire_identifiant,
            ["libelé"]: affaire.affaire_libelle,
            ["annee"]: affaire.affaire_annee,
            ["dosi"]: affaire.affaire_enjeux_dosi,
            ["pilote"]: affaire.affaire_pilote.collaborateur_nom + " " + affaire.affaire_pilote.collaborateur_prenom,
            ["email"]: affaire.affaire_pilote.collaborateur_email_pro,
            ["societe"]: this.getValuebyId(this.data.societes, "societe_nom_reduit", affaire.affaire_societe_id),
            ["etat"]: this.getValuebyId(this.data.etats, "description", affaire.affaire_etat_id),
            ["ADR applicable"]: affaire.affaire_adr_na != true ? "oui" : "non",
            ["ADR réalisée"]: affaire.affaire_adr_realise != true ? "non" : "oui",
            ["PDP applicable"]: affaire.affaire_pdp_na != true ? "oui" : "non",
            ["PDP réalisée"]: affaire.affaire_pdp_realise != true ? "non" : "oui",
            ["EDP applicable"]: affaire.affaire_edp_na != true ? "oui" : "non",
            ["EDP réalisée"]: affaire.affaire_edp_realise != true ? "non" : "oui",
            ["RTR applicable"]: affaire.affaire_rtr_na != true ? "oui" : "non",
            ["RTR réalisée"]: affaire.affaire_rtr_realise != true ? "non" : "oui",
            ["commentaire"]: affaire.affaire_hse_commentaire,
          });
      });

      return data;
    },
  },
  computed: {
    ...mapGetters({
      data: "affaires/data",
      users: "user/users",
    }),

    affairesFiltered() {
      let rep = this.affaires;

      if (this.filtreSociete) {
        rep = rep.filter((affaire) => affaire.affaire_societe_id == this.filtreSociete);
      }

      if (this.filtrePilote) {
        rep = rep.filter((affaire) => affaire.affaire_pilote_id == this.filtrePilote);
      }

      if (this.filtreEtat) {
        rep = rep.filter((affaire) => affaire.affaire_etat_id == this.filtreEtat);
      }

      if (this.filtreAnnee) {
        rep = rep.filter((affaire) => affaire.affaire_annee == this.filtreAnnee);
      }

      if (this.filtreAdr === 1) {
        rep = rep.filter(
          (affaire) =>
            affaire.affaire_adr_realise == true && (affaire.affaire_adr_na == false || affaire.affaire_adr_na == null)
        );
      }

      if (this.filtreAdr === 0) {
        rep = rep.filter(
          (affaire) =>
            (affaire.affaire_adr_realise == false || affaire.affaire_adr_realise == null) &&
            (affaire.affaire_adr_na == false || affaire.affaire_adr_na == null)
        );
      }

      if (this.filtreAdr === 2) {
        rep = rep.filter((affaire) => affaire.affaire_adr_na == true);
      }

      if (this.filtrePdp === 1) {
        rep = rep.filter(
          (affaire) =>
            affaire.affaire_pdp_realise == true && (affaire.affaire_pdp_na == false || affaire.affaire_pdp_na == null)
        );
      }

      if (this.filtrePdp === 0) {
        rep = rep.filter(
          (affaire) =>
            (affaire.affaire_pdp_realise == false || affaire.affaire_pdp_realise == null) &&
            (affaire.affaire_pdp_na == false || affaire.affaire_pdp_na == null)
        );
      }

      if (this.filtrePdp === 2) {
        rep = rep.filter((affaire) => affaire.affaire_pdp_na == true);
      }

      if (this.filtreEdp === 1) {
        rep = rep.filter(
          (affaire) =>
            affaire.affaire_edp_realise == true && (affaire.affaire_edp_na == false || affaire.affaire_edp_na == null)
        );
      }

      if (this.filtreEdp === 0) {
        rep = rep.filter(
          (affaire) =>
            (affaire.affaire_edp_realise == false || affaire.affaire_edp_realise == null) &&
            (affaire.affaire_edp_na == false || affaire.affaire_edp_na == null)
        );
      }

      if (this.filtreEdp === 2) {
        rep = rep.filter((affaire) => affaire.affaire_edp_na == true);
      }

      if (this.filtreRtr === 1) {
        rep = rep.filter(
          (affaire) =>
            affaire.affaire_rtr_realise == true && (affaire.affaire_rtr_na == false || affaire.affaire_rtr_na == null)
        );
      }

      if (this.filtreRtr === 0) {
        rep = rep.filter(
          (affaire) =>
            (affaire.affaire_rtr_realise == false || affaire.affaire_rtr_realise == null) &&
            (affaire.affaire_rtr_na == false || affaire.affaire_rtr_na == null)
        );
      }

      if (this.filtreRtr === 2) {
        rep = rep.filter((affaire) => affaire.affaire_rtr_na == true);
      }

      return rep;
    },
  },
  mounted() {
    this.getAffaires();
  },
};
</script>
<style>
@media (min-width: 768px) {
  .width-item {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .width-item {
    width: calc(100% / 2);
  }
}
@media (min-width: 1200px) {
  .width-item {
    width: calc(100% / 2);
  }
}
@media (min-width: 1500px) {
  .width-item {
    width: calc(100% / 3);
  }
}
</style>
